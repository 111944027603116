import React from 'react';
import { graphql } from 'gatsby';
import { Layout, Row, Col } from 'antd';
import Header from '../../components/PageLayout/Header';

import SidebarWrapper from '../../components/PageLayout/Sidebar';
import PostCard from '../../components/PostCard';
import SEO from '../../components/Seo';

const Blog = ({ data }) => (
  <Layout className="outerPadding">
    <Layout className="container">
      <Header />
      <SEO
        title="Blog"
        description="I like blogging about various web technologies and other stuff related to
          javascript, kubernetes, and reactjs. Basically love documented tips and tricks to overcome problems not widely spoke of"
        path="blog"
      />
      <SidebarWrapper>
        <div className="marginTopTitle">
          <h1 className="titleSeparate">Blog</h1>
        </div>
        <Row gutter={[20, 20]}>
          {
            data.allStrapiBlog && data.allStrapiBlog.edges.map((val, key) => (
              // eslint-disable-next-line react/no-array-index-key
              <Col key={key} xs={24} sm={24} md={12} lg={8}>
                <PostCard data={val} />
              </Col>
            ))
          }
        </Row>
      </SidebarWrapper>
    </Layout>
  </Layout>
);


export const query = graphql`
  query {
    allStrapiBlog {
      edges {
        node {
            strapiId
            path
            title
            tags
            excerpt
            cover{
              localFile{
                childImageSharp {
                  fluid(maxHeight: 600) {
                    ...GatsbyImageSharpFluid_tracedSVG
                  }
                }
              }
            }
        }
      }
    }
  }
`;

export default Blog;
